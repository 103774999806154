import { SegementTrackEvent } from './segment-helper';

export const pageScroll = (type, value) => {
  const data = {
    page_location: typeof window !== 'undefined' && window.location.href,
    ...value,
  };

  SegementTrackEvent(type, data, { sendGeoData: true });
};
